<template>
    <div v-if="error">
        <div class="alert alert-danger" role="alert">
            {{ error }}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { getAPI } from '../../utils/axios-api'
import { useRouter } from 'vue-router'

export default {
    name: "ResetPasswordHandler",
    props: ["uid","token"],
    setup(props) {   
        const router = useRouter();
        let uid = props.uid
        let token = props.token
        let url = '/account/password-reset/'+ uid + '/' + token + '/'
        const error = ref(null)

        getAPI.get(url).then((response) => {  
            console.log(response)
            if (response.status === 200){
                router.push({ name: "ResetPasswordAction", params: { uid: uid, token: token } });
                error.value = ''
            }  
        })
        .catch(err => {  
          console.log(err.response)
          if (err.response.status == 500){
              error.value = err.response.statusText
          }
          else{
              error.value = err.response.data.message
          }
         
        })
        return { error}

    }
}
</script>

<style>

</style>